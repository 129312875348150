<template>
  <section>

    <div class="image-uploader">
      <div class="image-uploader__images" v-if="images.length > 0">
        <h5>Uploaded Images</h5>
        <ul>
        <li v-for="(image,index) in images" :key="image" style="position: relative">
          <img :src="image" alt="">
          <v-icon @click="removeImage(index)" style="position: absolute; top: 5px; right: 5px; cursor: pointer" size="20" color="red">{{closeIcon}}</v-icon>
        </li>
      </ul>
      </div>
      <div class="image-uploader__upload" v-if="images.length < limit">
        <div @click="clickImageUploader" class="image-uploader__upload-box">
          <div class="image-uploader__upload-box-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/shopkarle-a28da.appspot.com/o/MISC_DATA%2FWEB_ASSETS%2FSVGs%2Fupload.svg?alt=media&token=91ba50ce-f924-44a9-ade2-a2dc6e707d0d" alt="">
            <h5>Click to Upload Images</h5>
          </div>
        </div>
      </div>
    </div>
    <v-file-input accept="image/*" :id="uploaderId" style="display: none"  @change="fileUploaded"></v-file-input>
    <v-dialog
        v-model="loading"
        persistent
        width="300"
    >
      <v-card
          color="#3f51b5"
          style="padding: 8px"
      >
        <v-card-text style="color: white">
          Uploading Image
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import {mdiClose} from '@mdi/js'
import axios from "axios"
export default {
  name: "image-uploader",
  props: ['uploaderId','limit', "apiImages"],
  data() {
    return {
      images:[],
      closeIcon: mdiClose,
      loading:false,
    }
  },
  watch: {
    apiImages(val) {
      if (val.length > 0) {
        this.images = val
        this.$emit('uploaded', [...this.images])
      }
    },
  },
  methods: {
    removeImage(index) {

      this.images.splice(index, 1)
      this.$emit('uploaded', [...this.images])

    },
    async fileUploaded(file) {
      this.loading = true;

      const webpUrl = await this.convertToWebp(file)

      console.log(webpUrl)
      this.images.push(webpUrl
      )
      this.loading = false
      this.$emit('uploaded', [...this.images])
    },
    clickImageUploader() {
      document.getElementById(this.uploaderId).click()
    },

    async convertToWebp(base64Image) {

      try {
        const bodyFormData = new FormData();
        bodyFormData.append("image", base64Image);

        const res = await axios({
          method: "post",
          url: "https://api.zyadashop.io/v2/utils/compressImage",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        return res.data.data.img
      } catch (e) {
        console.log(e)
      }

    }
  }
}
</script>

<style lang="scss" scoped>

.image-uploader {

  &__images {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;



    ul {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      scrollbar-width: none;
      -ms-overflow-style: none;
      width: 100%;
      overflow: auto;
      list-style: none;
      padding: 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    img {
      height: 100px;
      width: 100px;
      object-fit: contain;
      border: 1px solid #e1e1e1;
    }

  }

  &__upload {
    display: grid;
    place-items: center;
    width: 100%;
    &-box {

      width: 100%;
      height: 300px;
      display: grid;
      place-items: center;
      border: 2px #e1e1e1 dashed;
      cursor: pointer;

      &-content {
        display: flex;
        align-items: center;
        justify-self: center;
        flex-direction: column;

        img {
          width: 3rem;
          height: 3rem;
        }

        h5 {
          color: #86888A;
        }

      }
    }

  }

}

</style>