<template>
  <v-app>
    <div style=" width: 100% ;background-color: #3f51b5; position: sticky; top: 0; z-index: 1;padding: 10px; display: flex; align-items: center; justify-content: center;box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);">
      <img :src="require('@/assets/zyadashop_logo.png')" height="30" style="margin-bottom: -3px"/>
    </div>
    <v-main style="background-color: #f4f5f7">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    title: 'Reels Creator',
    titleTemplate: '%s | Zyadashop - Create online store free',
    meta: [{
      hid: "description",
      name: "description",
      content: "Generate Awesome videos in a single click.",
    },
      {
        hid: "title",
        name: "title",
        content: "Reels Creator",
      },]
  },

  data: () => ({
    //
  }),
};
</script>
