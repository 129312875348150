<template>
<v-container class="default-font">
    <section style="width: 100%; background-color: white" class="elevation-1 pt-8">

        <form-wizard ref="wizard" shape="circle" color="#3f51b5">
          <tab-content title="Choose Template" icon="mdi mdi-cellphone">
            <div class="header-container"><i style="font-size: 24px; margin-right: 8px" class="mdi mdi-information-outline"/>Choose a template</div>
            <div class="image-container">
              <v-card elevation="0" style="padding: 0 8px 0 8px; border-radius: unset;">
                <h4 style="width: 100%; text-align: center;padding-top: 12px; padding-bottom: 12px; position: relative">OUR VIDEOS</h4>
                <img v-if="currentSelectedTemplateData.template_url" class="flex-container" :src="currentSelectedTemplateData.template_url" />
                <img class="flex-image-container" src="https://firebasestorage.googleapis.com/v0/b/shopkarle-a28da.appspot.com/o/MISC_DATA%2FWEB_ASSETS%2Fmobile_wireframe.svg?alt=media&token=c2e843d7-5535-4a0a-9434-def1560f97eb" />
                <h5 style="width: 100%; text-align: center;padding-top: 12px; padding-bottom: 12px">{{currentSelectedTemplateData.template_name}}</h5>
              </v-card>
              <div>
                <v-card elevation="0" style="padding: 8px;border-radius: unset">
                  <div style="text-align: center; margin-bottom: 12px">Available template</div>
<!--                  <div class="scrolling-container">-->
<!--                    <div style="position: relative; cursor: pointer" v-for="(item, index) in templateData" :key="index">-->
<!--                      <img style="margin-right: 6px" @click="setCurrentSelectedImage(item)" width="100" :src="item.TEMPLATE_URL" />-->
<!--                      <i v-if="item.TEMPLATE_URL === currentSelectedTemplateData.template_url" style="position: absolute; bottom: 0; right: 40px; z-index: 1;color: #00E676; font-size: 28px" class="mdi mdi-check-circle"></i>-->
<!--                    </div>-->
<!--                  </div>-->
                  <v-row align-content="center" justify="center">
                    <v-col v-for="(item, index) in templateData" :key="index" cols="4" lg="2">
                                            <div style="position: relative">
                                              <img style="margin-right: 6px" @click="setCurrentSelectedImage(item)" width="100" :src="item.TEMPLATE_URL" />
                                              <i v-if="item.TEMPLATE_URL === currentSelectedTemplateData.template_url" style="position: absolute; bottom: 0;
                                             z-index: 1;color: #00E676; font-size: 28px" class="mdi mdi-check-circle right-position"></i>
                                            </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </tab-content>
          <tab-content :before-change="validateBusinessDetails" title="Enter Business Details" icon="mdi mdi-list-box">
            <div class="header-container"><i style="font-size: 24px; margin-right: 8px" class="mdi mdi-information-outline"/>Enter Business Details</div>
            <section style="margin-bottom: 12px;">
              <h3 style="margin-bottom: 12px">Business Details</h3>
              <v-row>
                <v-col cols="12" lg="6">
                  <span class="custom-text-label">Business name</span>
                  <input
                      v-model="store_data.business_name"
                      class="custom-text-field"
                      type="text"
                      inputmode
                      value
                      :maxlength="30"
                      oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <span :style="{
                    'float': 'right',
                    'font-size': '12px',
                    'color': store_data.business_name.length < 5 ? 'red' : 'green'
                  }">{{store_data.business_name.length}}/30</span>
                </v-col>
                <v-col cols="12" lg="6">
                  <span class="custom-text-label">Store Address</span>
                  <input
                      v-model="store_data.store_address"
                      class="custom-text-field"
                      type="text"
                      inputmode
                      value
                      :maxlength="30"
                      oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <span :style="{
                    'float': 'right',
                    'font-size': '12px',
                    'color': store_data.store_address.length < 5 ? 'red' : 'green'
                  }">{{store_data.store_address.length}}/30</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6">
                  <span class="custom-text-label">Store URL</span>
                  <input
                      v-model="store_data.store_url"
                      class="custom-text-field"
                      type="text"
                      inputmode
                      value
                  />
                  <span :style="{
                    'float': 'right',
                    'font-size': '12px',
                    'color': store_data.store_url.length < 5 ? 'red' : 'green'
                  }">{{store_data.store_url.length}}/30</span>
                </v-col>
                <v-col cols="12" lg="6">
                  <span class="custom-text-label">Contact Number</span>
                  <input
                      v-model="store_data.contact_number"
                      class="custom-text-field"
                      type="number"
                      inputmode
                      value
                      :maxlength="10"
                      oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <span :style="{
                    'float': 'right',
                    'font-size': '12px',
                    'color': store_data.contact_number.length < 10 ? 'red' : 'green'
                  }">{{store_data.contact_number.length}}/10</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6">
                  <span class="custom-text-label">Store Description</span>
                  <textarea
                      v-model="store_data.store_desc"
                      class="custom-text-field"
                      type="text"
                      inputmode
                      :maxlength="50"
                      oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <span :style="{
                    'float': 'left',
                    'font-size': '12px',
                  }"><i style="margin-right: 4px" class="mdi mdi-information-outline"></i>Special characters will be auto removed.</span>
                  <span :style="{
                    'float': 'right',
                    'font-size': '12px',
                    'color': store_data.store_desc.length < 5 ? 'red' : 'green'
                  }">{{store_data.store_desc.length}}/50</span>
                </v-col>
              </v-row>
            </section>

          </tab-content>
          <tab-content :before-change="validateTopProductsUpload" title="Upload Top Products" icon="mdi mdi-chart-box">
            <div class="header-container"><i style="font-size: 24px; margin-right: 8px" class="mdi mdi-information-outline"/>Upload your top 4 products</div>
            <ImageUploader uploaderId="top" key="topProduct" @uploaded="emitTopProduct($event)" :api-images="reelsData ? reelsData.body.top_products : []" :limit="4"/>
          </tab-content>

          <tab-content :before-change="validateRegularProductsUpload" title="Upload More Products" icon="mdi mdi-archive">
            <div class="header-container"><i style="font-size: 24px; margin-right: 8px" class="mdi mdi-information-outline"/>Upload 3 more products</div>
            <ImageUploader uploaderId="more" key="regProduct" @uploaded="emitRegularProduct($event)" :api-images="reelsData ? reelsData.body.some_product_images : []" :limit="3"/>
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
              <v-checkbox v-model="acceptTerms"></v-checkbox><span style="font-size: 12px">By using this link. I/We hereby accept all the terms and conditions under Zyadashop's Terms & Zyadashop's Privacy Policy. I/ We further agree  (i) all content uploaded via this link is owned and independently created by me/us only for my/our products/services. (ii) to not upload any objectionable or illegal content via this link with any unauthorized user.</span>
            </div>
          </tab-content>
          <tab-content title="Generated" icon="mdi mdi-checkbox-marked-circle">
            <div class="header-container"><i style="font-size: 24px; margin-right: 8px" class="mdi mdi-information-outline"/>Preview your template</div>
            <div v-if="!videoData" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 290px">
              <v-card elevation="0" style="padding: 0 8px 0 8px; border-radius: unset; width: 250px;align-items: center; display: flex; flex-direction: column">
                <v-progress-circular color="#3f51b5" indeterminate></v-progress-circular>
                <span style="text-align: center; margin-top: 12px">Initialising...</span>
              </v-card>
            </div>
            <div v-if="story_building" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 290px">
              <v-card v-if="videoData && videoData.STATUS === 'build'" elevation="0" style="padding: 0 8px 0 8px; border-radius: unset; width: 250px;align-items: center; display: flex; flex-direction: column">
              <v-progress-circular color="#3f51b5" indeterminate></v-progress-circular>
              <span style="text-align: center; margin-top: 12px">Creating your video...</span>
            </v-card>
            </div>
            <div v-else-if="!story_building && videoData && videoData.STATUS === 'done'" class="image-container">
              <v-card elevation="0" style="padding: 0 8px 0 8px; border-radius: unset;">
                <h4 v-if="preview_video" style="width: 100%; text-align: center;padding-top: 12px; padding-bottom: 12px; position: relative">Preview your video</h4>
                <video style="background-color: black" class="flex-container-video" controls autoplay>
                  <source :src="preview_video" type="video/mp4">
                </video>
                <img class="flex-image-container" src="https://firebasestorage.googleapis.com/v0/b/shopkarle-a28da.appspot.com/o/MISC_DATA%2FWEB_ASSETS%2Fmobile_wireframe.svg?alt=media&token=c2e843d7-5535-4a0a-9434-def1560f97eb" />
              </v-card>
            </div>
            <div v-if="videoData && videoData.STATUS === 'error'" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 290px">
              <v-card elevation="0" style="padding: 0 8px 0 8px; border-radius: unset; width: 250px;align-items: center; display: flex; flex-direction: column">
                <span style="text-align: center; margin-top: 12px">Something Went Wrong</span>
                <v-btn @click="resetWizard" text style="margin-top: 10px" color="#3f51b5">Retry</v-btn>
              </v-card>
            </div>
          </tab-content>


<!--          FOOTER SLOT TO BE ADDED-->

          <template slot="footer" scope="props">
            <div class="wizard-footer-left">
              <div
                  @click="props.prevTab()"
                  v-if="props.activeTabIndex > 0 && !props.isLastStep"
                  slot="back"
                  class="wizard-btn"
                  style="background-color: rgb(85, 110, 230);border-color: rgb(85, 110, 230); color: white;margin-top: 16px;">
                Back
              </div>
            </div>
            <div class="wizard-footer-right">
              <div v-if="!props.isLastStep" @click="props.nextTab()" slot="next" class="wizard-btn" style="background-color: rgb(85, 110, 230); border-color: rgb(85, 110, 230); color: white; margin-top: 16px">
                <span v-if="api_loading"><v-progress-circular size="20" indeterminate></v-progress-circular></span>
                <span v-else>Next</span>
              </div>
              <div v-else slot="next" class="wizard-btn" style="background-color: rgb(85, 110, 230); border-color: rgb(85, 110, 230); color: white;">
                <div @click="downloadPdf" style="display: flex; align-items: center; justify-content: center"><i style="font-size: 18px; margin-right: 4px" class="mdi mdi-download"></i><span>Download Video</span></div>
              </div>
            </div>
          </template>
        </form-wizard>

    </section>
</v-container>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/image-uploader";
import {db} from '@/main';
import { collection, getDocs, onSnapshot, doc, query, orderBy } from "firebase/firestore";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
export default {
  name: "home",
  components: {ImageUploader},
  data() {
    return {
      reelsData: null,
      store_data: {
        business_name: '',
        store_address: '',
        store_desc: '',
        contact_number: '',
        store_url: ''
      },
      topProductsArray: [],
      regularProductsArray: [],
      currentSelectedTemplateData: {
        template_name: null,
        template_url: null,
        templateId: null
      },
      templateData: [],
      api_loading: false,
      story_building: false,
      preview_video: null,
      videoData: null,
      acceptTerms: false,
      isValidStoreID: true
    }
  },

  async created() {
   await this.fetchTemplates()
    if (this.$route.query.storeID) {
      try {
        this.reelsData = await this.getReelsData(this.$route.query.storeID)

        if (this.reelsData && !this.reelsData.status) {
          this.$awn.alert(this.reelsData.rsn)
          return;
        }
        this.store_data = {
          business_name: this.reelsData.body.meta.store_name,
          store_address: this.reelsData.body.meta.store_addr,
          store_desc: this.reelsData.body.meta.store_desc,
          contact_number: this.reelsData.body.meta.store_helpline,
          store_url: `${this.reelsData.body.meta.store_domain}${ this.reelsData.body.meta.store_domain.includes('gostore.app') ? '/' + this.$route.query.storeID : ''}`
        }
      } catch (e) {
        this.isValidStoreID = false;
        this.$awn.alert(e.message);
      }
    }

  },

  methods: {
    fetchTemplates() {

      const q = query(collection(db, "TEMPLATES"), orderBy("ADDED", "desc"))

      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.templateData.push(doc.data())
        })
        this.currentSelectedTemplateData.template_url = this.templateData[0].TEMPLATE_URL;
        this.currentSelectedTemplateData.template_name = this.templateData[0].TEMPLATE_NAME;
        this.currentSelectedTemplateData.templateId = this.templateData[0].TEMPLATE_ID;
      })

    },
    setCurrentSelectedImage(currentTemplate) {
      this.currentSelectedTemplateData.template_url = currentTemplate.TEMPLATE_URL;
      this.currentSelectedTemplateData.template_name = currentTemplate.TEMPLATE_NAME;
      this.currentSelectedTemplateData.templateId = currentTemplate.TEMPLATE_ID;
    },
    validateBusinessDetails() {
      return new Promise((resolve, reject) => {
        if (this.store_data.business_name.length > 30 || this.store_data.business_name.length < 5) {
          this.$awn.info('Business name should be of 5 to 30 characters')
          reject();
          return;
        }
        if (this.store_data.store_url < 5) {
          this.$awn.info('Store Url should be at least 5 characters')
          reject();
          return;
        }
        if (this.store_data.store_address.length > 40 || this.store_data.store_address.length < 5) {
          this.$awn.info('Store Address should be of 5 to 40 characters')
          reject();
          return;
        }
        if (this.store_data.contact_number.length < 10) {
          this.$awn.info('Please add valid phone number')
          reject();
          return;
        }
        if (this.store_data.store_desc > 50 || this.store_data.store_desc.length < 5) {
          this.$awn.info('Business Description should be of 5 to 50 characters');
          reject();
          return;
        }
        resolve(true)
      })
    },
    validateTopProductsUpload() {
      return new Promise((resolve, reject) => {
        if (this.topProductsArray.length < 4) {
          this.$awn.info('Please add 4 images');
          reject()
          return;
        }
        resolve(true);
      })
    },
    emitTopProduct(imgArray) {
      this.topProductsArray = imgArray
    },
    emitRegularProduct(imgArray) {
      this.regularProductsArray = imgArray
    },
    resetWizard() {
      this.$refs.wizard.maxStep = 0
      this.$refs.wizard.tabs.forEach((tab)=>{
        tab.checked = false
      })
      this.$refs.wizard.navigateToTab(0)
      this.store_data = {
            business_name: '',
            store_address: '',
            store_desc: '',
            contact_number: '',
            store_url: ''
      }
      this.topProductsArray = []
      this.regularProductsArray = []
    },
    validateRegularProductsUpload() {
      return new Promise( async (resolve, reject) => {
        if (this.regularProductsArray.length < 3) {
          this.$awn.info('Please add 3 images');
          reject()
          return;
        }
        if (!this.acceptTerms) {
          this.$awn.info('Please accept terms and conditions');
          reject()
          return;
        }
        // //call API here
        // // console.log(this.topProductsArray)
        let concatedImageArray = this.topProductsArray.concat(this.regularProductsArray)
        let requestObj = {
          template : this.currentSelectedTemplateData.templateId,
          "images_set": concatedImageArray,
          texts: {
            "store_name": this.store_data.business_name.replace(/[^a-zA-Z0-9 ]/g, ""),
            "location": this.store_data.store_address,
            "description": this.store_data.store_desc.replace(/[^a-zA-Z0-9 ]/g, ""),
            "mobile": this.store_data.contact_number,
            "link": this.store_data.store_url
          }
        }
        console.log(requestObj)
        this.api_loading = true;
        let config = {};
        if (this.$route.query.storeID && this.isValidStoreID) {
          config = {
            headers: {
              storeID: this.$route.query.storeID
            }
          }
        }
        try {
          axios.post(
            "https://creator.zyadashop.io",
            {
              requestObj: requestObj
            },
          config
          ).then((res) => {
            if (res.data.status === 0) {
              this.api_loading = false;
              this.$awn.alert(res.data.rsn);
              return;
            }
            if (res.data.status === 1) {
              this.fetchVideo(res.data.rid);
              this.api_loading = false;
              resolve(true);
            }
            if (!res) {
              this.$awn.alert('Something Went Wrong!');
              reject();
            }
          })
        } catch (e) {
          this.api_loading = false;
          console.log(e)
          this.$awn.alert(e.message);
        }
        resolve(true);
      })
    },

    downloadPdf() {
      if (!this.preview_video) {
        this.$awn.alert('Please wait..')
        return;
      };
      var ctx = this;
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.preview_video, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        console.log(this.response)
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.target = '_blank';
        tag.download = ctx.currentSelectedTemplateData.templateId;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      }
      xhr.send();
    },
    fetchVideo(docId) {

      onSnapshot(doc(db, "REELS_BUILDS", docId), (doc) => {
        if (doc.exists) {
          this.videoData = doc.data();
          this.story_building = doc.data().STATUS === 'build';
          if (doc.data().STATUS === 'done' && doc.data().LINK) {
            this.preview_video = doc.data().LINK
          }
          if (doc.data().STATUS === 'error') {
            this.$awn.alert('Something went wrong')
          }
        }
      });

    },
    async getReelsData(storeId) {

      const res = await axios.post("https://api.zyadashop.io/v2/app/getBasicDataForReels", {}, {
       headers: {
         storeID: storeId,
       }
      })
      return res.data;

    },
  }
}
</script>

<style>
.custom-text-label {
  font-weight: 600;
}
.row--dense {
  margin: 0;
}
.image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.scrolling-container {
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 550px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  align-items: center;
  justify-content: center;
}
.scrolling-container::-webkit-scrollbar {
  display: none;
}
.custom-text-field {
  background: #f2f2f2;
  padding: 7px 28px 6px 12px;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  border: 1px solid transparent;
  outline: none;
  border-radius: 2px;
  color: #4d4d4d;
  margin-top: 6px;
}

.flex-image-container {
  width: 270px;
  height: 480px;
}
.flex-container {
  width: 210px;
  height: 455px;
  position: absolute;
  top: 62px;
  left: 38px;
  border-radius: 18px
}
.flex-container-video {
  width: 210px;
  height: 455px;
  position: absolute;
  top: 62px;
  left: 38px;
  border-radius: 18px
}
.header-container {
  padding: 18px 24px;
  background-color: #FFF8E1;
  color: #FFA000;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}
.right-position {
  right: 18px;
}

@media only screen and (max-width: 1025px) {
  .stepTitle {
    display: none !important;
  }
  .image-container{
    flex-direction: column;
  }
.scrolling-container {
  width: 320px;
}
.right-position {
  right: 27px;
}
}

</style>