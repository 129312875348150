import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "@/router";
import './style.css'
import VueFormWizard from "vue-form-wizard/src";
import './assets/_form-wizard.scss'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { enableIndexedDbPersistence } from "firebase/firestore";
import "firebase/firestore";
import VueAWN from "vue-awesome-notifications";
import VueMeta from 'vue-meta'

Vue.use(VueMeta);

let options = {
  position: "top-right",
  maxNotifications: 5,
  icons: {
    enabled: false
  },
  durations: {
    global: 4000,
    info: 2000
  }
};
Vue.use(VueAWN, options);
Vue.config.productionTip = false
Vue.use(VueFormWizard);
const firebaseConfig = {
  apiKey: "AIzaSyCfaItYlmYmtpagfnLreTeIfROFKh67QlI",
  authDomain: "reels-creator.firebaseapp.com",
  projectId: "reels-creator",
  storageBucket: "reels-creator.appspot.com",
  messagingSenderId: "994581134368",
  appId: "1:994581134368:web:cce451bfbfaa29c60d1045",
  measurementId: "G-T7Z8GJE4TF"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

enableIndexedDbPersistence(db)
    .catch(function(err) {
      if (err.code == "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
