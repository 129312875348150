import Vue from 'vue';
import Router from 'vue-router';
import Home from "@/pages/home";

Vue.use(Router);



// const Home = () => import("./pages/home");

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        }
    ]
})

export default router;